//@flow
import React from 'react'
import BlogLayout from './BlogLayout';
import { graphql } from 'gatsby';

export const query = (data) => {
  return graphql`
      query($slug: String!) {
          ghostPost(slug: {eq:$slug}){
              slug
              title
              html
          }
      }
  `
}

const PostLayout = ({data: { ghostPost: post }}) => {
  return <BlogLayout>
    <h1>{post.title}</h1>
    <section
      className="content-body load-external-scripts"
      dangerouslySetInnerHTML={{ __html: post.html }}
    />
  </BlogLayout>
}

export default PostLayout
